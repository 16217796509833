export enum ApiTags {
	PROFILE_DETAIL = 'profile_detail',
	PROFILE = 'profile',
	QUESTION_DETAIL = 'question_detail',
	QUESTIONS_LEARNED = 'question_learned',
	QUESTIONS = 'questions',
	SKILLS = 'skills',
	NEW_QUIZ = 'newQuiz',
	INTERVIEW_QUIZ = 'inteview_quiz',
	HISTORY_QUIZ = 'historyQuiz',
	SPECIALIZATIONS = 'specializations',
	SPECIALIZATION_DETAIL = 'specialization_detail',
	INTERVIEW_STATISTICS = 'interview_statistics',
}
